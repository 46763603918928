import {
    createGtmSubscriber,
    GTM_ID_FACILE_IT,
    makeGTMUrl,
    partyTownConfigVanilla,
    SerpicoCustomVar,
    SerpicoSessionCustom,
    SerpicoStore,
    SerpicoUser,
} from '@facile-it/serpico-core'
import { createSerpicoStore } from '@facile-it/serpico-core'
import { createSerpicoSession } from '@facile-it/serpico-core'

export interface RunConf {
    state?: { session?: SerpicoSessionCustom; user?: SerpicoUser; customVar?: SerpicoCustomVar }
    dataLayerName?: string
}

let _store: undefined | SerpicoStore

export const getStore = () => {
    if (typeof _store === 'undefined') {
        throw new Error('Store not runned')
    }

    return _store
}

export const resetStore = () => {
    _store = undefined
}

export const run = (conf: RunConf = {}) => {
    const session = createSerpicoSession(conf.state?.session)(window)

    const store = createSerpicoStore({
        session,
        user: conf.state?.user,
        customVar: conf.state?.customVar,
    })

    const { onNext } = createGtmSubscriber(window, conf.dataLayerName)

    store.subscribe(onNext)

    _store = store
}

export const partyTownConfigRegisterGlobal = (config: Parameters<typeof partyTownConfigVanilla>[0] = {}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.partytown = partyTownConfigVanilla(config)
}

export const getGtmFacileItUrl = () => makeGTMUrl(GTM_ID_FACILE_IT)

import type { SerpicoCustomVar } from './models'
import type { CustomVarFormInfo, CustomVarInsuranceRca } from './modelsCustomVar'

type StateValue = boolean | number | string | undefined | StateRecord | StateArray

export interface StateRecord {
    readonly [key: keyof any]: StateValue
}

export interface StateArray extends ReadonlyArray<StateValue> {}

type GtmDecorated<A> = A extends boolean
    ? 'S' | 'N'
    : A extends StateRecord | StateArray
    ? { [k in keyof A]: GtmDecorated<A[k]> }
    : A

export function gtmRecursiveDecorator<T>(o: T): GtmDecorated<T> // this is an hack for this https://github.com/microsoft/TypeScript/issues/15300 interface vs type
export function gtmRecursiveDecorator<T extends StateRecord | StateArray>(o: T) {
    const no = (Array.isArray(o) ? [] : {}) as
        | {
              [key: keyof any]: StateValue
          }
        | Array<StateValue>

    for (const k in o) {
        if (typeof o[k] === 'object' && o[k] !== null) {
            no[k] = gtmRecursiveDecorator(o[k] as T)
        } else if (typeof o[k] === 'boolean') {
            no[k] = o[k] ? 'S' : 'N'
        } else if (typeof o[k] !== 'undefined') {
            no[k] = o[k]
        }
    }

    return no
}

// -------------------------------------------------------------------------------------
// CustomVar
// -------------------------------------------------------------------------------------

type CustomVarDecoratorOur<I, E> = (Omit<I, keyof E> & E) | undefined

function gtmDecoratorFormInfo(formInfo: CustomVarFormInfo | undefined): CustomVarDecoratorOur<
    CustomVarFormInfo,
    {
        path?: string
        fieldsInError?: string
    }
> {
    return formInfo
        ? {
              ...formInfo,
              path: formInfo.path?.join(' > '),
              fieldsInError: formInfo.fieldsInError?.join(', '),
          }
        : undefined
}

function gtmDecoratorInsuranceRca(insuranceRca: CustomVarInsuranceRca | undefined): CustomVarDecoratorOur<
    CustomVarFormInfo,
    {
        insuranceEffectiveDate?: string
        vehicleRegistrationDateYear?: string
    }
> {
    return insuranceRca
        ? {
              ...insuranceRca,
              insuranceEffectiveDate: insuranceRca.insuranceEffectiveDate?.toISOString().substring(0, 10),
              vehicleRegistrationDateYear: insuranceRca.vehicleRegistrationDateYear?.toISOString().substring(0, 4),
          }
        : undefined
}

export function gtmDecoratorCustomVar(customVar: SerpicoCustomVar | undefined): CustomVarDecoratorOur<
    SerpicoCustomVar,
    {
        formInfo: ReturnType<typeof gtmDecoratorFormInfo>
        insuranceRca: ReturnType<typeof gtmDecoratorInsuranceRca>
    }
> {
    return {
        ...customVar,
        formInfo: gtmDecoratorFormInfo(customVar?.formInfo),
        insuranceRca: gtmDecoratorInsuranceRca(customVar?.insuranceRca),
    }
}

// -------------------------------------------------------------------------------------
// Constant
// -------------------------------------------------------------------------------------

export const DATA_LAYER_NAME = 'dataLayer'

export const GTM_ID_FACILE_IT = 'GTM-NNPHWV6'
export const GA_ID_FACILE_IT_DEV = 'UA-22342495-55'
export const GA_ID_FACILE_IT_PROD = 'UA-22342495-50'

export const GTM_HOST = 'https://www.googletagmanager.com'
export const GA_HOST = 'https://www.google-analytics.com'

// -------------------------------------------------------------------------------------
// PartyTown
// -------------------------------------------------------------------------------------

interface PartyTownConfig {
    lib: string
    debug?: boolean
    dataLayer?: string
}

const makeLib = (lib: string) => ({
    lib,
})

export const proxyUrl = 'https://cdn.builder.io/api/v1/proxy-api'

const makeResolveUrl = () =>
    `resolveUrl(url) { ` +
    `if (${['google-analytics'].map(u => `url.hostname.includes("${u}")`).join(' || ')}) {` +
    ` var proxyUrl = new URL("${proxyUrl}"); proxyUrl.searchParams.append("url", url); return proxyUrl;` +
    `}` +
    `}`
const makeForward = (dataLayer = DATA_LAYER_NAME) => ({
    forward: [`${dataLayer}.push`],
})

const makeDebug = (debug?: boolean) => (debug ? { debug: true } : {})

const partyTownConfigCommon = ({ lib, debug, dataLayer }: PartyTownConfig) => ({
    ...makeLib(lib),
    ...makeForward(dataLayer),
    ...makeDebug(debug),
})

export const partyTownSnippetConfig = (c: PartyTownConfig) => {
    const cs = JSON.stringify(partyTownConfigCommon(c))

    return `partytown = {${cs.slice(1, cs.length - 1)},  ${makeResolveUrl()} };`
}

export const partyTownSnippetConfigNext = (c: Omit<PartyTownConfig, 'lib'>) =>
    partyTownSnippetConfig({ ...c, lib: '/_next/static/~partytown/' })

export const partyTownConfigVanilla = (c: Omit<PartyTownConfig, 'lib'>) => ({
    ...partyTownConfigCommon({ ...c, lib: '/~partytown/' }),
    resolveUrl(url: URL) {
        if (url.hostname.includes('google-analytics')) {
            const pu = new URL(proxyUrl)
            pu.searchParams.append('url', url.toString())
            return pu
        }

        return url
    },
})

// -------------------------------------------------------------------------------------
// GTM
// -------------------------------------------------------------------------------------

export const makeGTMUrl = (gtmId: string) => `${GTM_HOST}/gtm.js?id=${gtmId}`

export const gtmNoScriptSnippet = ({ gtmId }: { gtmId: string }) =>
    `<iframe src="${makeGTMUrl(gtmId)}" height="0" width="0" style="display: none; visibility: hidden;"></iframe>`

export const gtmScriptSnippet = ({ gtmId, dataLayer = DATA_LAYER_NAME }: { gtmId: string; dataLayer?: string }) =>
    `(function(w,d,s,l){w[l]=w[l]||[];w[l].push({'gtm.start':` +
    `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
    `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
    `'${makeGTMUrl(gtmId)}'+dl;f.parentNode.insertBefore(j,f);` +
    `})(window,document,'script','${dataLayer}');`

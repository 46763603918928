import { v4 } from '@lukeed/uuid'
import type { SerpicoSession, SerpicoSessionCustom } from './models'

interface SessionIdBrand {
    readonly SessionId: unique symbol
}

export type SessionId = SessionIdBrand & string

export const SERPICO_SESSION_ID_LOCAL_STORAGE_KEY = 'SERPICO_SESSION_ID_v1'

const uuidV4Regex = /^FIT-[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export const sessionIdDecode = (s: string | null): s is SessionId => s !== null && uuidV4Regex.test(s)

export const createSessionId = () => `FIT-${v4()}` as SessionId

export const loadOrCreateSessionId = (ls: typeof window.localStorage): SessionId => {
    const s = ls.getItem(SERPICO_SESSION_ID_LOCAL_STORAGE_KEY)

    if (sessionIdDecode(s)) {
        return s
    }

    const ns = createSessionId()
    ls.setItem(SERPICO_SESSION_ID_LOCAL_STORAGE_KEY, ns)

    return ns
}

const isMobile = (w: typeof window) => {
    return w.innerWidth < 768 && w.matchMedia('(pointer: coarse)').matches
}

export const createSerpicoSession =
    (sessionCustom: SerpicoSessionCustom = {}) =>
    (w: typeof window): SerpicoSession => {
        return {
            ...sessionCustom,
            sessionId: loadOrCreateSessionId(w.localStorage),
            device: isMobile(w) ? 'Mobile' : 'Desktop',
        }
    }

import { v4 } from '@lukeed/uuid'
import type {
    SerpicoCustomVar,
    SerpicoEvent,
    PayloadPage,
    SerpicoUser,
    PayloadMisc,
    SerpicoSession,
    PayloadInteractionTypeAction,
    PayloadInteractionTypePageView,
    PayloadEvent,
    PayloadEcommerce,
    PayloadInteractionTypeTransaction,
    SerpicoMeta,
    SerpicoEventInteraction,
    SerpicoEventData,
} from './models'

type PayloadBufferedPageView = PayloadInteractionTypePageView & DispatchPageViewPayload
type PayloadBufferedInteraction = PayloadInteractionTypeTransaction & DispatchTransactionPayload
type PayloadBuffered = PayloadBufferedPageView | PayloadBufferedInteraction | DispatchActionPayload

interface EventPartialMeta {
    meta: Pick<SerpicoMeta, 'createdAt'>
}

type EventBuffered = Omit<SerpicoEventInteraction, 'payload' | 'meta'> & {
    payload: PayloadBuffered
} & EventPartialMeta

export interface SerpicoStateBase {
    session: SerpicoSession
    user: SerpicoUser
    customVar: SerpicoCustomVar
}

export interface SerpicoState extends SerpicoStateBase {
    lastPage?: PayloadPage
}

export type DispatchPageViewPayload = Omit<PayloadPage, 'prevTrackPage'> & PayloadEvent
export type DispatchActionPayload = PayloadInteractionTypeAction & PayloadEvent & PayloadMisc
export type DispatchTransactionPayload = PayloadEcommerce

export interface SerpicoSubscriber {
    (state: SerpicoEvent): void
}

export interface SerpicoUnsubscriber {
    (): void
}

export interface SerpicoStore {
    getState: () => SerpicoState
    getStoreId: () => string

    setUser: (f: (u: SerpicoUser) => SerpicoUser) => void
    setSession: (f: (d: SerpicoSession) => SerpicoSession) => void
    setCustomVar: (f: (d: SerpicoCustomVar) => SerpicoCustomVar) => void

    dispatchPageView: (d: DispatchPageViewPayload, setter?: (s: SerpicoStateBase) => SerpicoStateBase) => void
    dispatchAction: (d: DispatchActionPayload, setter?: (s: SerpicoStateBase) => SerpicoStateBase) => void
    dispatchTransaction: (d: DispatchTransactionPayload, setter?: (s: SerpicoStateBase) => SerpicoStateBase) => void

    subscribe: (listener: SerpicoSubscriber) => SerpicoUnsubscriber
}

const eventBufferedToSerpicoEvent = (
    event: EventBuffered,
    lastPage: SerpicoState['lastPage']
): SerpicoEventData & EventPartialMeta => {
    if (event.payload.interactionType === 'pageView') {
        return {
            ...event,
            payload: {
                ...event.payload,
                prevTrackPage: lastPage?.trackPage,
            },
        }
    }

    return {
        ...event,
        payload: {
            ...event.payload,
            ...(lastPage as PayloadPage),
        },
    }
}

const buildPartialMeta = (): EventPartialMeta => ({
    meta: {
        createdAt: new Date().toISOString(),
    },
})

const buildMeta = (createdAt: string, counter: number): SerpicoMeta => ({ createdAt, counter, _id: v4(), version: '0' })

export const createSerpicoStore = ({
    session,
    user,
    customVar,
}: {
    session: SerpicoSession
    user?: SerpicoUser
    customVar?: SerpicoCustomVar
}): SerpicoStore => {
    // -------------------------------------------------------------------------------------
    // init
    // -------------------------------------------------------------------------------------

    const _storeId = v4()

    let _eventCounter = 0

    let _state: SerpicoState = {
        session,
        user: user ?? {
            logged: false,
        },
        customVar: customVar ?? {},
    }

    const _listeners = new Set<SerpicoSubscriber>()

    const _buffer: Array<EventBuffered> = []
    const _replay: Array<SerpicoEvent> = []
    let _initialized = false

    // -------------------------------------------------------------------------------------
    // run dispatch
    // -------------------------------------------------------------------------------------

    const doDispatch = (event: SerpicoEventData & EventPartialMeta) => {
        const ed = { ...event, meta: buildMeta(event.meta.createdAt, _eventCounter++) }
        _replay.push(ed)
        if (_replay.length > 200) {
            _replay.shift()
        }
        _listeners.forEach(l => l(ed))
    }

    // -------------------------------------------------------------------------------------
    // buffered dispatch
    // -------------------------------------------------------------------------------------

    const bufferedDispatch = (event: EventBuffered) => {
        if (_initialized) {
            doDispatch(eventBufferedToSerpicoEvent(event, _state.lastPage))

            return tearDownDispatch(event)
        }

        if (event.payload.interactionType === 'pageView') {
            doDispatch(eventBufferedToSerpicoEvent(event, _state.lastPage))
            flushBufferAndInitialize(event.payload)

            return tearDownDispatch(event)
        }

        _buffer.push(event)
    }

    const flushBufferAndInitialize = (payload: PayloadBufferedPageView) => {
        _buffer.forEach(eb =>
            doDispatch(
                eventBufferedToSerpicoEvent(eb, {
                    pageCategory: payload.pageCategory,
                    pageType: payload.pageType,
                    trackPage: payload.trackPage,
                })
            )
        )

        _buffer.splice(0, _buffer.length)
        _initialized = true
    }

    const tearDownDispatch = (event: EventBuffered) => {
        if (event.payload.interactionType === 'pageView') {
            _state.lastPage = {
                prevTrackPage: _state.lastPage?.trackPage,
                pageCategory: event.payload.pageCategory,
                pageType: event.payload.pageType,
                trackPage: event.payload.trackPage,
            }
        }
    }

    // -------------------------------------------------------------------------------------
    // dispatch
    // -------------------------------------------------------------------------------------

    const dispatchPageView = (d: DispatchPageViewPayload, setter?: (s: SerpicoStateBase) => SerpicoStateBase) => {
        setterSerpicoStateBase(setter)

        const event: EventBuffered = {
            event: 'interaction',
            payload: {
                interactionType: 'pageView',
                interactionScope: 'navigation',
                ...d,
            },
            session: _state.session,
            user: _state.user,
            customVar: _state.customVar,
            ...buildPartialMeta(),
        }

        bufferedDispatch(event)
    }

    const dispatchAction = (d: DispatchActionPayload, setter?: (s: SerpicoStateBase) => SerpicoStateBase) => {
        setterSerpicoStateBase(setter)

        const event: EventBuffered = {
            event: 'interaction',
            payload: d,
            session: _state.session,
            user: _state.user,
            customVar: _state.customVar,
            ...buildPartialMeta(),
        }

        bufferedDispatch(event)
    }

    const dispatchTransaction = (
        { ecommerce }: DispatchTransactionPayload,
        setter?: (s: SerpicoStateBase) => SerpicoStateBase
    ) => {
        setterSerpicoStateBase(setter)

        const event: EventBuffered = {
            event: 'interaction',
            payload: {
                interactionType: 'transaction',
                interactionScope: 'conversion',
                ecommerce,
            },
            session: _state.session,
            user: _state.user,
            customVar: _state.customVar,
            ...buildPartialMeta(),
        }

        bufferedDispatch(event)
    }

    // -------------------------------------------------------------------------------------
    // getter & setter
    // -------------------------------------------------------------------------------------

    const getStoreId = () => _storeId

    const getState = () => _state

    const setterSerpicoStateBase = (f?: (s: SerpicoStateBase) => SerpicoStateBase) => {
        if (f) {
            const { session: s, user: u, customVar: cv } = _state
            _state = { ..._state, ...f({ session: s, user: u, customVar: cv }) }
        }
    }

    const setUser: SerpicoStore['setUser'] = f => {
        _state = { ..._state, user: f(_state.user) }
    }

    const setSession: SerpicoStore['setSession'] = f => {
        _state = { ..._state, session: f(_state.session) }
    }

    const setCustomVar: SerpicoStore['setCustomVar'] = f => {
        _state = { ..._state, customVar: f(_state.customVar) }
    }

    // -------------------------------------------------------------------------------------
    // subscribe
    // -------------------------------------------------------------------------------------

    const subscribe: SerpicoStore['subscribe'] = listener => {
        _replay.forEach(e => listener(e))
        _listeners.add(listener)

        return () => _listeners.delete(listener)
    }

    // -------------------------------------------------------------------------------------
    // init
    // -------------------------------------------------------------------------------------

    doDispatch({
        event: 'serpicoInit',
        ...buildPartialMeta(),
    })

    // -------------------------------------------------------------------------------------
    // export
    // -------------------------------------------------------------------------------------

    return {
        getState,
        getStoreId,
        setUser,
        setCustomVar,
        setSession,
        dispatchPageView,
        dispatchAction,
        dispatchTransaction,
        subscribe,
    }
}

import { gtmDecoratorCustomVar, gtmRecursiveDecorator } from './decorators'
import type { SerpicoEvent, SerpicoEventInteraction } from './models'
import { DATA_LAYER_NAME } from './snippet'

declare global {
    interface Window {
        dataLayer?: Array<SerpicoEvent>
    }
}

interface GtmSubscriber {
    onNext: (event: SerpicoEvent) => void
    onDestruct: () => void
}

const decorateInteraction = ({ customVar, payload, ...e }: SerpicoEventInteraction) =>
    gtmRecursiveDecorator({
        ...e,
        payload: {
            ecommerce: null,
            ...payload,
        },
        customVar: gtmDecoratorCustomVar(customVar),
    })

const decorate = (e: SerpicoEvent) => (e.event === 'interaction' ? decorateInteraction(e) : e)

export const createGtmSubscriber = (w: typeof window, dataLayerName: string = DATA_LAYER_NAME): GtmSubscriber => {
    const _buffer: Array<SerpicoEvent> = []
    let _initialized = false

    const pushToDl = (e: SerpicoEvent) => {
        const dl = (w as any)[dataLayerName]
        dl.push(decorate(e))
    }

    const init = () => {
        const dl = (w as any)[dataLayerName]

        if (typeof dl === 'undefined') {
            return false
        }

        _buffer.forEach(pushToDl)
        _buffer.splice(0, _buffer.length)
        _initialized = true

        return true
    }

    init()

    let currentWait = 0
    const intervalId = setInterval(() => {
        if (_initialized || init()) {
            clearInterval(intervalId)
            return
        }
        if (currentWait >= 12) {
            clearInterval(intervalId)
            return
        }
        currentWait++
    }, 1000)

    const onNext = (e: SerpicoEvent) => {
        if (_initialized) {
            pushToDl(e)

            return
        }

        _buffer.push(e)
    }

    const onDestruct = () => {
        clearInterval(intervalId)
    }

    return {
        onNext,
        onDestruct,
    }
}
